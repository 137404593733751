import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/about/privacy": [6],
		"/driver": [~7,[2],[3]],
		"/driver/about": [9,[2],[3]],
		"/driver/data/[shortUserId]": [~10,[2],[3]],
		"/driver/instrucciones": [11,[2],[3]],
		"/driver/instructions": [12,[2],[3]],
		"/driver/login": [13,[2],[3]],
		"/driver/mode-demploi": [14,[2],[3]],
		"/driver/opt-out": [15,[2],[3]],
		"/driver/opt-out/survey/[slug]": [16,[2],[3]],
		"/driver/settings": [~17,[2],[3]],
		"/driver/settings/[slug]": [18,[2],[3]],
		"/driver/studies/[studyShortcode]/argyle": [~19,[2],[3]],
		"/driver/[slug]": [8,[2],[3]],
		"/dsc/opt-out": [20],
		"/dsc/privacy": [21],
		"/organizer": [22,[4]],
		"/organizer/login": [23,[4]],
		"/organizer/org/[affiliate_organization_key]": [~24,[4]],
		"/organizer/org/[affiliate_organization_key]/[driver_short_id]": [~25,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';